import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  PRIMARY_OUTLET,
} from '@angular/router';

import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { DataService, UserService } from '../../services';
import { User } from '../../interfaces/User';
import { TRANSACTION_USER_BALANCE } from '../../constants/apis-list';

@Component({
  selector: 'app-breadcrumb',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: any;
  public title?: string;
  public user: User | null | undefined;

  @Input() hasBackButton?: boolean;
  @Input() headerTitle?: string;
  @Input() details?: any;

  TOTAL_BALANCE: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    public dataService: DataService
  ) {
    this.user = this.userService.user;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === PRIMARY_OUTLET))
      .subscribe((route: any) => {
        const snapshot = this.router.routerState.snapshot;
        const title = route.snapshot.data.title;
        const parent = route.parent.snapshot.data.breadcrumb;
        const child = route.snapshot.data.breadcrumb;
        this.breadcrumbs = {};
        this.title = title === 'User Profile' ? '' : title;
        this.breadcrumbs = {
          parentBreadcrumb: parent,
          childBreadcrumb: child,
        };
      });
  }

  ngOnInit() {
    this.loadBalance();
  }

  loadBalance() {
    if (this.details) {
      return this.dataService
        .fetchData({
          apiUrl: TRANSACTION_USER_BALANCE.replace(
            '{id}',
            String(this.details?.id).toString()
          ),
          method: 'GET',
          contentType: 'application/json',
        })
        .subscribe((response) => {
          this.TOTAL_BALANCE = response.data.total_balance;
        });
    }
  }
}
